<template>
  <a
    v-if="isExternalLink"
    v-bind="$attrs"
    :href="to"
    target="_blank"
    class="text-white"
    :class="{ disabled, active, 'no-hover': noHover }"
  >
    <slot />
  </a>
  <router-link
    v-else
    v-bind="$props"
    custom
    v-slot="{ href, navigate }"
  >
    <a
      v-bind="$attrs"
      :href="href"
      @click="navigate"
      :class="{ disabled, active, 'no-hover': noHover }"
      class="text-white"
    >
      <slot />
    </a>
  </router-link>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  name: 'AppLink',
  inheritAttrs: false,

  props: {
    ...RouterLink.props,
    disabled: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    noHover: { type: Boolean, default: false },
  },

  computed: {
    isExternalLink() {
      return typeof this.to === 'string' && this.to.startsWith('http');
    },
  },
};
</script>

<style scoped>
a:hover:not(.no-hover) {
  @apply underline;
}

.active {
  @apply text-indigo-400;
}

.disabled {
  @apply text-gray-400 pointer-events-none hover:no-underline;
}
</style>
