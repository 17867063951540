<template>
  <nav class="bg-slate-900">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center h-16">
        <div class="flex items-center md:hidden text-white">
          <font-awesome-icon
            @click="toggleLeftMenu"
            size="2x"
            :icon="['fas', 'bars']"
          />
          <img
            src="/assets/images/logo.png"
            class="w-20 ml-20"
          >
        </div>
        <div class="hidden md:flex">
          <Link
            to="/"
            class="mr-10"
          >
            <img
              src="/assets/images/logo.png"
              class="w-20"
            >
          </Link>

          <ul class="flex items-center">
            <li>
              <Link
                to="/"
                class="nav-link"
                no-hover
                :class="{ active: route.name === 'Home' }"
              >
                Feed
              </Link>
            </li>
            <li>
              <Link
                to="/bands/my/"
                class="nav-link"
                no-hover
                :class="{ active: route.name === 'MyBands' }"
              >
                Minhas bandas
              </Link>
            </li>
          </ul>
        </div>

        <!-- <div class="md:hidden sm:block border rounded p-2 border-indigo-300">
          <span class="text-yellow-500 text-xl">500</span><span class="text-yellow-500"> pts</span>
        </div> -->
        <div class="flex">
          <!-- <div
            class="hidden md:block border rounded p-2 border-indigo-300 mr-10"
          >
            <span class="text-indigo-300">
              {{ currentUser?.name }}, sua pontuação:
            </span>
            <span class="text-yellow-500 text-xl">500</span><span class="text-yellow-500"> pts</span>
          </div> -->
          <ul class="flex items-center">
            <li>
              <Dropdown>
                <div class="flex items-center gap-2">
                  <UserAvatar :url="currentUser?.profile_picture.url" />
                  <span class="text-white font-light text-sm">{{
                    currentUser?.name
                  }}</span>
                  <font-awesome-icon
                    :icon="['fas', 'caret-down']"
                    class="text-white"
                  />
                </div>
                <template #items>
                  <DropdownItem
                    @click="
                      goTo({
                        name: 'UserProfile',
                        params: { id: currentUser.id },
                      })
                    "
                  >
                    Meu perfil
                  </DropdownItem>
                  <DropdownItem @click="logout">
                    Sair
                  </DropdownItem>
                </template>
              </Dropdown>
            </li>
            <li />
          </ul>
        </div>
      </div>
    </div>

    <div
      class="left-menu"
      :class="{ 'left-0': showLeftMenu }"
    >
      <ul class="text-white text-lg">
        <li
          class="py-2 px-5"
          :class="{ active: route.name === 'Home' }"
        >
          <Link
            to="/"
            no-hover
          >
            Feed
          </Link>
        </li>

        <li
          class="py-2 px-5"
          :class="{ active: route.name === 'MyBands' }"
        >
          <Link
            to="/bands/my/"
            no-hover
          >
            Minhas bandas
          </Link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useAuthStore from '../stores/auth.store';
import Link from './Link.vue';
import UserAvatar from './UserAvatar.vue';
import Dropdown from './Dropdown.vue';
import DropdownItem from './DropdownItem.vue';

const route = useRoute();
const router = useRouter();

const showLeftMenu = ref(false);

window.addEventListener('resize', () => {
  showLeftMenu.value = false;
});

const toggleLeftMenu = () => {
  showLeftMenu.value = !showLeftMenu.value;
};

const logout = async () => {
  const authStore = useAuthStore();
  await authStore.logout();
  router.push('/auth');
};

const currentUser = computed(() => {
  const authStore = useAuthStore();
  return authStore.currentUser;
});

const goTo = (to) => {
  router.push(to);
};
</script>

<style>
.nav-link {
  @apply !text-slate-500 border-b-4 border-slate-500 pl-4 pr-4 pb-[18px] pt-[15px];
}

.nav-link:hover {
  @apply !text-slate-300 border-slate-300;
}

.nav-link.active {
  @apply !text-yellow-500 border-yellow-500;
}

.nav-link.active:hover {
  @apply !text-yellow-400 border-yellow-400;
}

.left-menu {
  @apply absolute h-full bg-slate-800 w-[300px] z-50 md:-left-[300px];
  left: -300px;
  transition: all 0.5s ease 0s;
}

.left-menu ul li.active {
  background-color: theme('colors.indigo.500');
  color: theme('colors.indigo.100');
}
</style>
