import axios from 'axios';
// import router from './router';

export const getToken = () => (localStorage.getItem('jwt_token') ? localStorage.getItem('jwt_token') : null);

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

const api = axios.create({
  baseURL: 'api/',
});

api.interceptors.request.use(
  (config) => {
    const requestConfig = config;
    const token = localStorage.getItem('jwt_token');
    if (token) {
      requestConfig.headers.Authorization = getAuthorizationHeader();
    }
    return requestConfig;
  },
  (error) => Promise.reject(error),
);

// TODO: It's causing dependency cycle, fix and uncomment it later
// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       router.push('/unauthorized');
//     }
//     return Promise.reject(error);
//   },
// );

export default api;
