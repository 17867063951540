<template>
  <NavBar />
  <div
    class="fixed flex items-center justify-center w-full h-full top-0 bottom-0"
    v-if="loading"
  >
    <font-awesome-icon
      size="4x"
      :icon="['fas', 'spinner']"
      class="text-white"
      spin
    />
  </div>
  <main class="container mx-auto px-5 columns is-gapless is-multiline">
    <div
      v-if="showProfileWarning"
      class="border border-red-500 rounded p-4 bg-red-100 text-red-900 my-5"
    >
      Com seu perfil completo fica mais fácil recomendar bandas e amigos para
      você.
      <a
        :href="`/#/users/${currentUser.id}`"
        class="text-indigo-900 hover:underline"
      >Clique aqui</a>
      para completar o seu perfil.
    </div>

    <slot v-if="!!currentUser && !loading" />
  </main>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import useAuthStore from '../stores/auth.store';
import NavBar from './NavBar.vue';

defineProps({ loading: { type: Boolean, default: false } });
const route = useRoute();
const authStore = useAuthStore();
if (!authStore.currentUser) {
  await authStore.fetchCurrentUser();
}
const currentUser = computed(() => authStore.currentUser);

const showProfileWarning = computed(
  () => route.name !== 'Profile'
    && !!currentUser.value
    && !currentUser.value.is_profile_complete,
);
</script>

<style scoped></style>
