<template>
  <div>
    <label
      class="text-indigo-400"
      :for="name"
      v-if="!!label"
    >
      {{ label }}
    </label>
    <v-select
      class="rounded w-full bg-white text-indigo-600 focus:outline-none focus:shadow-outline"
      :id="name"
      :name="name"
      v-model="value"
      :options="options"
      :multiple="multiple"
      :taggable="taggable"
    />
    <div
      class="rounded w-full py-1 px-3 bg-rose-50 text-rose-500 mt-1"
      v-if="!!error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps([
  'modelValue',
  'label',
  'name',
  'error',
  'options',
  'multiple',
  'taggable',
]);
const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});
</script>

<style>
@import 'vue-select/dist/vue-select.css';
</style>
