<template>
  <div>
    <label
      class="text-indigo-400"
      :for="name"
      v-if="!!label"
    >
      {{ label }}
    </label>

    <div
      class="flex items-center gap-3"
      v-if="!!imagePreview"
    >
      <img
        :src="imagePreview"
        alt="User Profile Picture Preview"
        class="w-40"
      >
      <Button
        size="sm"
        @on-click="removeCurrentImage"
      >
        Remover imagem
      </Button>
    </div>
    <input
      class="rounded w-full py-2 text-indigo-600 leading-tight focus:outline-none focus:shadow-outline"
      :id="name"
      :name="name"
      type="file"
      ref="inputFile"
      @change="uploadFile()"
      v-if="!imagePreview"
    >
    <div
      class="rounded w-full py-1 px-3 bg-rose-50 text-rose-500 mt-1"
      v-if="!!error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Button from './MButton.vue';

const props = defineProps({
  label: { type: String, default: 'm' },
  currentFileUrl: { type: String, default: '' },
  name: { type: String, default: '' },
  error: { type: String, default: null },
  onFileChange: { type: Function, default: null },
});

const inputFile = ref(null);
const imagePreview = ref(props.currentFileUrl || null);

const removeCurrentImage = () => {
  imagePreview.value = null;
  props.onFileChange(null);
};

const uploadFile = () => {
  const file = inputFile.value.files[0];

  // Create a FileReader to read the image contents
  const reader = new FileReader();
  reader.onload = () => {
    imagePreview.value = reader.result;
  };
  // Read the selected image as a data URL
  reader.readAsDataURL(file);

  props.onFileChange(inputFile.value.files[0]);
};
</script>

<style scoped></style>
