import { createRouter, createWebHashHistory } from 'vue-router';

import Home from '../views/Home.vue';
import Auth from '../views/Auth.vue';
import Login from '../views/Login.vue';
import Signup from '../views/Signup.vue';
import EditProfile from '../views/users/EditProfile.vue';
import UserProfile from '../views/users/UserProfile.vue';
import Instruments from '../views/users/Instruments.vue';
import NewBand from '../views/bands/NewBand.vue';
import EditBand from '../views/bands/EditBand.vue';
import MyBands from '../views/bands/MyBands.vue';
import BandDetails from '../views/bands/BandDetails.vue';
import NewInstrument from '../views/instrument/NewInstrument.vue';
import EditInstrument from '../views/instrument/EditInstrument.vue';
import PageNotFound from '../views/PageNotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    children: [
      {
        path: '',
        name: 'Login',
        component: Login,
      },
      {
        path: 'signup',
        name: 'Signup',
        component: Signup,
      },
    ],
  },
  {
    path: '/users',
    name: 'Users',
    children: [
      {
        path: ':id',
        name: 'UserProfile',
        component: UserProfile,
      },
      {
        path: ':id/edit',
        name: 'EditProfile',
        component: EditProfile,
      },
      {
        path: ':id/instruments',
        name: 'Instruments',
        component: Instruments,
      },
      {
        path: ':id/instruments/new',
        name: 'NewInstrument',
        component: NewInstrument,
      },
      {
        path: ':id/instruments/:instrument_id/edit',
        name: 'EditInstrument',
        component: EditInstrument,
      },
    ],
  },
  {
    path: '/bands',
    name: 'Bands',
    children: [
      {
        path: 'my',
        name: 'MyBands',
        component: MyBands,
      },
      {
        path: 'new',
        name: 'NewBand',
        component: NewBand,
      },
      {
        path: ':id/',
        name: 'BandDetails',
        component: BandDetails,
      },
      {
        path: ':id/edit',
        name: 'EditBand',
        component: EditBand,
      },
    ],
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: PageNotFound,
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: PageNotFound },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to) => {
  // Not importing form authStore to avoid cyclic dependency
  const token = localStorage.getItem('jwt_token');
  if (to.name !== 'Login' && to.name !== 'Signup' && !token) {
    return '/auth';
  }
  if ((to.name === 'Login' || to.name === 'Signup') && !!token) {
    return '/';
  }

  return true;
});

export default router;
