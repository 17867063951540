import { defineStore } from 'pinia';

const useBandStore = defineStore({
  id: 'bands',
  state: () => ({
    count: 0,
    items: [],
  }),
});

export default useBandStore;
