<template>
  <span
    class="group inline-flex items-center pr-2 rounded-full ease-in-out bg-indigo-500 hover:bg-indigo-400 text-white"
    :class="{
      'pl-2': !slots.image,
      'highlight': highlight
    }"
  >
    <div class="flex items-center space-x-2">
      <slot name="image" />
      <slot />
    </div>
    <span
      v-if="editable"
      class="cursor-pointer ml-1 opacity-40 hover:opacity-90"
      @click="handleRemove"
    >
      <font-awesome-icon
        icon="fa-solid fa-remove"
        size="sm"
      />
    </span>
  </span>
</template>

<script setup>
import { useSlots } from 'vue';

const emit = defineEmits(['onRemove']);
defineProps({
  editable: { type: Boolean, default: false },
  highlight: { type: Boolean, default: false },
});
const slots = useSlots();
const handleRemove = () => {
  emit('onRemove');
};
</script>

<style scoped>

.highlight {
  @apply bg-teal-600 hover:bg-teal-700;
}
</style>
