<template>
  <div class="text-white text-4xl text-center mt-2">
    Cadastro
  </div>

  <form @submit.prevent="save">
    <TextInput
      class="mb-4"
      name="name"
      label="Nome"
      v-model="registrationData.name"
      :error="errors.name"
    />
    <TextInput
      class="mb-4"
      name="email"
      type="email"
      label="E-mail"
      v-model="registrationData.email"
      :error="errors.email"
    />
    <TextInput
      class="mb-4"
      name="password"
      type="password"
      label="Defina uma senha"
      v-model="registrationData.password"
      :error="errors.password"
    />
    <TextInput
      class="mb-4"
      name="password_confirmation"
      type="password"
      label="Repetir senha"
      v-model="registrationData.password_confirmation"
      :error="errors.password_confirmation"
    />
    <RadioInput
      name="gender"
      label="Sexo"
      :options="genderOptions"
      v-model="registrationData.gender"
      :error="errors.gender"
    />

    <div
      v-if="submitErrors?.length > 0"
      class="rounded w-full py-1 px-3 bg-rose-50 text-rose-500 mt-3 mb-3"
    >
      <ul>
        <li
          v-for="error in submitErrors"
          :key="error"
        >
          - {{ error }}
        </li>
      </ul>
    </div>

    <div class="flex flex-col items-center">
      <MButton
        variant="primary"
        type="submit"
      >
        Cadastrar
      </MButton>
      <MButton
        variant="link"
        @on-click="$router.push('/auth/')"
        class="mt-4"
      >
        Voltar para o login
      </MButton>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import MButton from '../components/MButton.vue';
import RadioInput from '../components/RadioInput.vue';
import TextInput from '../components/TextInput.vue';

export default {
  name: 'Signup',
  components: { TextInput, RadioInput, MButton },
  data() {
    return {
      registrationData: {},
      genderOptions: [
        { value: 'm', label: 'Masculino' },
        { value: 'f', label: 'Feminino' },
        { value: 'o', label: 'Outro' },
      ],
      errors: {},
      submitErrors: [],
    };
  },
  methods: {
    validateSubmit() {
      const requiredFields = [
        'name',
        'email',
        'password',
        'password_confirmation',
        'gender',
      ];

      const errors = requiredFields?.reduce((errorsSoFar, fieldName) => {
        if (!this.registrationData[fieldName]) {
          return { ...errorsSoFar, [fieldName]: 'Este campo é obrigatório' };
        }
        return errorsSoFar;
      }, {});

      if (
        !errors.password
        && !errors.password_confirmation
        && this.registrationData.password
          !== this.registrationData.password_confirmation
      ) {
        errors.password = 'As senhas não combinam.';
      }

      return errors;
    },
    save() {
      const errors = this.validateSubmit();

      if (Object.keys(errors).length > 0) {
        this.errors = errors;
        return;
      }

      const payload = {
        user: this.registrationData,
      };

      axios
        .post('users/', payload)
        .then(() => this.$router.push('/auth/'))
        .catch((res) => {
          this.submitErrors = res.response.data.errors;
        })
        .finally(() => {
          this.errors = {};
        });
    },
  },
};
</script>

<style></style>
