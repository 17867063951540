<template>
  <AppPage :loading="data.loadingUser || !data.user">
    <PageCard class="mt-10">
      <div class="flex justify-between">
        <div class="flex flex-row items-center">
          <UserAvatar
            size="lg"
            :url="data.user?.profile_picture?.url"
            class="mr-2 hidden md:block"
          />
          <div class="flex flex-col ml-3 text-white gap-3">
            <div class="text-4xl">
              <span>{{ data.user.name }}</span>
            </div>

            <div class="flex gap-2 items-center flex-wrap">
              <Pill
                highlight
                v-if="!!data.user?.selected_profile_type"
              >
                {{ data.user?.selected_profile_type.label }}
              </Pill>

              <Pill
                v-for="(tag, index) in tags"
                :key="`${tag}-${index}`"
              >
                {{ tag }}
              </Pill>
            </div>

            <div class="flex items-center gap-2 font-light">
              <span>
                <font-awesome-icon
                  :icon="['fas', 'location-dot']"
                  class="text-white"
                />
                {{ data.user.city }}, {{ data.user.state }}
              </span>
              <span>|</span>
              <span>
                <font-awesome-icon
                  :icon="['fas', 'clock']"
                  class="text-white"
                />
                Desde {{ userSince }}
              </span>
            </div>
          </div>
        </div>

        <Dropdown>
          <MButton
            size="m"
            variant="link"
            :icon="['fas', 'ellipsis-v']"
          />
          <template #items>
            <DropdownItem
              @click="
                () =>
                  $router.push({
                    name: 'Instruments',
                    params: { id: data.user.id },
                  })
              "
            >
              <font-awesome-icon
                :icon="['fas', 'guitar']"
                size="sm"
              />
              Seus instrumentos
            </DropdownItem>
            <DropdownItem
              @click="
                () =>
                  $router.push({
                    name: 'EditProfile',
                    params: { id: data.user.id },
                  })
              "
            >
              <font-awesome-icon
                :icon="['fas', 'edit']"
                size="sm"
              />
              Editar perfil
            </DropdownItem>
          </template>
        </Dropdown>
      </div>
    </PageCard>

    <div class="md:grid md:grid-cols-3 md:grid-flow-col gap-5 mt-5 sm:flex sm:flex-col">
      <div class="flex flex-col gap-5 md:col-span-2">
        <PageCard>
          <h1 class="text-2xl text-yellow-500">
            Bio
          </h1>

          <RichTextReader :html-value="data.user.bio" />
        </PageCard>

        <PageCard>
          <h1 class="text-2xl text-yellow-500 mb-2">
            Próximos eventos
          </h1>

          <div class="flex gap-5 text-white flex-wrap">
            <div class="flex flex-col border rounded-sm p-2 overflow-hidden w-[250px]">
              <a
                class="text-lg hover:underline ml-1 truncate"
                href="#"
              >Meu Evento com Título bem Grande Mesmo</a>
              <span class="font-extralight text-sm">
                <font-awesome-icon
                  :icon="['fas', 'clock']"
                  class="text-indigo-300 w-5"
                />
                5 de setembro às 14h
              </span>
              <span class="font-extralight text-sm">
                <font-awesome-icon
                  :icon="['fas', 'location-dot']"
                  class="text-indigo-300 w-5"
                />
                Recife, PE
              </span>
            </div>
            <div class="flex flex-col border rounded-sm p-2">
              <a
                class="text-lg hover:underline ml-1 truncate"
                href="#"
              >Meu Evento</a>
              <span class="font-extralight text-sm">
                <font-awesome-icon
                  :icon="['fas', 'clock']"
                  class="text-indigo-300 w-5"
                />
                5 de setembro às 14h
              </span>
              <span class="font-extralight text-sm">
                <font-awesome-icon
                  :icon="['fas', 'location-dot']"
                  class="text-indigo-300 w-5"
                />
                Recife, PE
              </span>
            </div>
            <div class="flex flex-col border rounded-sm p-2">
              <a
                class="text-lg hover:underline ml-1 truncate"
                href="#"
              >Meu Evento</a>
              <span class="font-extralight text-sm">
                <font-awesome-icon
                  :icon="['fas', 'clock']"
                  class="text-indigo-300 w-5"
                />
                5 de setembro às 14h
              </span>
              <span class="font-extralight text-sm">
                <font-awesome-icon
                  :icon="['fas', 'location-dot']"
                  class="text-indigo-300 w-5"
                />
                Recife, PE
              </span>
            </div>
          </div>
          <div class="text-indigo-200 font-extralight">
            e mais 3
          </div>
        </PageCard>
      </div>

      <div class="mt-5 md:col-span-1 md:mt-0">
        <PageCard>
          <h1 class="text-2xl text-yellow-500 mb-2">
            Bandas
          </h1>
          <div
            class="flex flex-col gap-1 text-white"
            v-if="!!data.user.band_memberships?.length"
          >
            <a
              class="flex gap-3 items-center cursor-pointer hover:bg-slate-800"
              v-for="band in data.user.band_memberships"
              :key="band.id"
              :href="`/#/bands/${band.id}`"
            >
              <img
                class="text-indigo-400 rounded-sm w-1/5"
                :src="band.cover_picture?.url"
              >
              <span class="">{{ band.name }}</span>
            </a>
          </div>
          <div
            class="text-white font-extralight"
            v-else
          >
            Nenhuma até agora :(
          </div>
        </PageCard>

        <PageCard class="mt-5">
          <h1 class="text-2xl text-yellow-500">
            Instrumentos
          </h1>
          <div class="flex flex-col text-white">
            <div v-if="!data.user.musical_instruments?.length">
              Nenhum até agora :(
            </div>
            <div
              v-else
              v-for="instrument in data.user.musical_instruments"
              :key="instrument.id"
            >
              <span class="text-indigo-400">{{ instrument.name }}</span> -
              <span class="font-thin text-sm">{{ instrument.model }}</span>
            </div>
          </div>
        </PageCard>
      </div>
    </div>
  </AppPage>
</template>

<script setup>
import { computed, onMounted, reactive } from 'vue';
import { useRoute } from 'vue-router';

import AppPage from '../../components/AppPage.vue';
import api from '../../api';
import UserAvatar from '../../components/UserAvatar.vue';
import PageCard from '../../components/PageCard.vue';
import MButton from '../../components/MButton.vue';
import useAuthStore from '../../stores/auth.store';
import Pill from '../../components/Pill.vue';
import { sinceMonth } from '../../dates';
import Tooltip from '../../components/Tooltip.vue';
import RichTextReader from '../../components/form/RichTextReader.vue';
import Dropdown from '../../components/Dropdown.vue';
import DropdownItem from '../../components/DropdownItem.vue';

const route = useRoute();
const userId = route.params.id;

const data = reactive({ user: null, loadingUser: false });

const authStore = useAuthStore();
const currentUser = computed(() => authStore.currentUser);
const userSince = computed(() => sinceMonth(data.user?.created_at));

const tags = computed(() => {
  if (!data.user) return [];

  return [...data.user.musical_genres, ...data.user.skills];
});

const fetchUser = () => {
  data.loadingUser = true;
  api
    .get(`users/${userId}/profile`)
    .then((res) => {
      data.user = res.data;
    })
    .finally(() => {
      data.loadingUser = false;
    });
};

onMounted(() => {
  fetchUser();
});
</script>

<style scoped></style>
