<template>
  <AppPage :loading="loadingInstruments">
    <Breadcrumbs class="mt-5">
      <Link :to="{ name: 'UserProfile', params: { id: currentUser.id } }">
        Meu perfil
      </Link>
      <Link
        disabled
        to="#"
      >
        Meus instrumentos
      </Link>
    </Breadcrumbs>
    <PageCard class="mt-5 mb-2">
      <div class="flex justify-between items-center">
        <div>
          <h1 class="text-2xl text-yellow-500">
            Instrumentos
          </h1>
          <p class="text-white">
            Conta aí quais instrumentos você toca!
          </p>
        </div>
        <MButton
          :icon="['fas', 'plus']"
          @click="
            $router.push({
              name: 'NewInstrument',
              params: { id: currentUser.id },
            })
          "
        >
          Novo
        </MButton>
      </div>
    </PageCard>

    <PageCard v-if="!instruments.length">
      <div class="text-white font-extralight">
        Nenhum instrumento até agora.
      </div>
    </PageCard>

    <div v-else>
      <div class="text-white pl-4 font-extralight">
        Mostrando {{ instruments.length }} de {{ instrumentsCount }}
      </div>
      <table
        class="w-full table-fixed text-indigo-100 border-separate border-spacing-y-2"
      >
        <thead class="w-full text-left">
          <tr class="w-full">
            <th class="pl-4 pr-4">
              Nome
            </th>
            <th class="pl-4 pr-4">
              Modelo
            </th>
            <th class="pl-4 pr-4">
              Toca desde
            </th>
            <th class="w-[38px]" />
          </tr>
        </thead>
        <tbody class="gap-2 w-full">
          <tr
            class="bg-slate-900 rounded-md w-full items-center text-left mb-5"
            v-for="instrument in instruments"
            :key="instrument.id"
          >
            <td class="p-4">
              {{ instrument.name }}
            </td>
            <td class="p-4">
              {{ instrument.model ?? '-' }}
            </td>
            <td class="p-4">
              {{ instrument.plays_since ? since(instrument.plays_since) : '-' }}
            </td>
            <td class="text-right">
              <Dropdown>
                <MButton
                  size="m"
                  variant="link"
                  :icon="['fas', 'ellipsis-v']"
                />
                <template #items>
                  <DropdownItem
                    @click="
                      $router.push({
                        name: 'EditInstrument',
                        params: {
                          id: currentUser.id,
                          instrument_id: instrument.id,
                        },
                      })
                    "
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-edit"
                      size="sm"
                    />
                    Editar
                  </DropdownItem>
                  <DropdownItem
                    @click="() => handleSelectInstrumentToBeDeleted(instrument)"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-trash"
                      size="sm"
                    />
                    Deletar
                  </DropdownItem>
                </template>
              </Dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <Paginator
        :current-page="currentPage"
        :pages-number="pagesNumber"
        :current-page-offset="2"
        @page-change="(page) => (currentPage = page)"
      />
    </div>

    <ConfirmActionModal
      :show="!!instrumentToBeDeleted"
      :title="confirmRemoveInstrumentTitle"
      :on-close-modal="closeRemoveInstrumentModal"
      :on-confirm-action="handleConfirmRemoveInstrument"
      :loading="isDeletingInstrument"
    />
  </AppPage>
</template>

<script>
import api from '../../api';
import AppPage from '../../components/AppPage.vue';
import Dropdown from '../../components/Dropdown.vue';
import DropdownItem from '../../components/DropdownItem.vue';
import MButton from '../../components/MButton.vue';
import PageCard from '../../components/PageCard.vue';
import useAuthStore from '../../stores/auth.store';
import { sinceMonth } from '../../dates';
import ConfirmActionModal from '../../components/ConfirmActionModal.vue';
import Paginator from '../../components/Paginator.vue';
import Breadcrumbs from '../../components/Breadcrumbs.vue';
import Link from '../../components/Link.vue';

export default {
  components: {
    AppPage,
    PageCard,
    MButton,
    Dropdown,
    DropdownItem,
    ConfirmActionModal,
    Paginator,
    Breadcrumbs,
    Link,
  },
  data() {
    return {
      instruments: [],
      instrumentsCount: 0,
      currentPage: 1,
      pagesNumber: 0,
      loadingInstruments: false,
      instrumentToBeDeleted: null,
      isDeletingInstrument: false,
    };
  },
  computed: {
    confirmRemoveInstrumentTitle() {
      return `Deseja remover o instrumento ${this.instrumentToBeDeleted?.name}?`;
    },
    currentUser() {
      const authStore = useAuthStore();
      const { currentUser } = authStore;
      return currentUser;
    },
  },
  mounted() {
    this.loadInstrument();
  },
  watch: {
    currentPage(newPage) {
      this.loadInstrument(newPage);
    },
  },
  methods: {
    loadInstrument(page = 1) {
      this.loadingInstruments = true;
      api
        .get(`musical_instruments?page=${page}&user_id=${this.currentUser.id}`)
        .then((res) => {
          this.instruments = res.data.items;
          this.instrumentsCount = res.data.count;
          this.pagesNumber = res.data.pages;
        })
        .finally(() => {
          this.loadingInstruments = false;
        });
    },
    since(date) {
      return sinceMonth(date);
    },
    handleSelectInstrumentToBeDeleted(instrument) {
      this.instrumentToBeDeleted = instrument;
    },
    closeRemoveInstrumentModal() {
      this.instrumentToBeDeleted = null;
    },
    handleConfirmRemoveInstrument() {
      this.isDeletingInstrument = true;
      api
        .delete(`musical_instruments/${this.instrumentToBeDeleted.id}`)
        .then(() => {
          this.loadInstrument();
          this.instrumentToBeDeleted = null;
        })
        .finally(() => {
          this.isDeletingInstrument = false;
        });
    },
  },
};
</script>

<style scoped></style>
