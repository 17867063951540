// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Toast from 'vue-toastification';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import {
  faUserSecret,
  faSpinner,
  faPlus,
  faEdit,
  faRemove,
  faLocationDot,
  faClock,
  faGuitar,
  faTrash,
  faEllipsisV,
  faCaretLeft,
  faCaretRight,
  faMinus,
  faCaretDown,
  faBars,
} from '@fortawesome/free-solid-svg-icons';

import VueSelect from 'vue-select';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import App from '../components/App.vue';
import router from '../router';
import '../assets/tailwind.css';
import 'vue-toastification/dist/index.css';

const pinia = createPinia();

library.add(
  faUserSecret,
  faSpinner,
  faPlus,
  faEdit,
  faRemove,
  faLocationDot,
  faClock,
  faGuitar,
  faTrash,
  faEllipsisV,
  faCaretLeft,
  faCaretRight,
  faCaretDown,
  faMinus,
  faBars,
);

const toastOptions = {};
createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('v-select', VueSelect)
  .component('VueDatePicker', VueDatePicker)
  .use(pinia)
  .use(Toast, toastOptions)
  .use(router)
  .mount('#app');
