<template>
  <nav
    class="breadcrumb"
    ref="breadcrumbs"
  >
    <slot />
  </nav>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const breadcrumbs = ref(null);

onMounted(() => {
  const links = breadcrumbs.value.querySelectorAll('a');
  links.forEach((link, index) => {
    if (index !== links.length - 1) {
      const separator = document.createElement('span');
      separator.className = 'separator';
      separator.textContent = '/';
      link.parentNode.insertBefore(separator, link.nextSibling);
    }
  });
});
</script>

<style>
.breadcrumb {
  @apply flex text-white gap-3 font-light;
}
.breadcrumb .separator {
  @apply font-extralight;
  line-height: normal;
}
</style>
