<template>
  <div
    class="relative toggle-button"
    ref="dropdown"
  >
    <div
      ref="clickableBtn"
      class="cursor-pointer"
    >
      <slot />
    </div>

    <div
      v-if="show"
      class="dropdown"
    >
      <slot name="items" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const show = ref(false);
const dropdown = ref(null);
const clickableBtn = ref(null);

const handleClick = (event) => {
  if (clickableBtn.value.contains(event.target)) {
    show.value = true;
  }

  if (!dropdown.value.contains(event.target)) {
    show.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClick);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClick);
});
</script>

<style scoped>
.dropdown {
  @apply flex flex-col absolute text-white text-left bg-slate-800 rounded-sm whitespace-nowrap after:content-none border right-3;
  min-width: 160px;
  z-index: 9999;
}
</style>
