<template>
  <AppPage :loading="loadingBands || !bands">
    <div class="mt-10 flex">
      <h1 class="text-3xl text-yellow-500 mr-5">
        Suas bandas ({{ bandsCount }})
      </h1>
      <MButton
        icon="plus"
        @click="$router.push(`/bands/new`)"
      >
        Adicionar
      </MButton>
    </div>
    <BandsList :bands="bands" />
  </AppPage>
</template>

<script setup>
import {
  computed, ref, reactive, onMounted,
} from 'vue';
import MButton from '../../components/MButton.vue';
import AppPage from '../../components/AppPage.vue';
import useBandStore from '../../stores/bands.store';
import BandsList from './BandsList.vue';
import useAuthStore from '../../stores/auth.store';
import api from '../../api';

const bands = reactive([]);
const loadingBands = ref(false);
const bandStore = useBandStore();
const bandsCount = computed(() => bandStore.count);

const authStore = useAuthStore();
const currentUser = computed(() => authStore.currentUser);

const loadBands = () => {
  loadingBands.value = true;
  const params = { user_id: currentUser?.value.id };
  api
    .get('bands/', { params })
    .then((res) => {
      bands.value = res.data;
      bandStore.items = bands.value;
      bandStore.count = bands.value?.length;
    })
    .finally(() => {
      loadingBands.value = false;
    });
};

onMounted(() => {
  loadBands();
});
</script>

<style></style>
