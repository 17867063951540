export const STATE_OPTIONS = [
  { value: null, label: 'Selecione um estado' },
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
];

export const INSTRUMENT_OPTIONS = [
  'Vocal',
  'Violão',
  'Guitarra',
  'Baixo',
  'Bateria',
  'Teclado',
  'Piano',
  'Saxofone',
  'Trompete',
  'Violino',
  'Flauta',
  'Clarinete',
  'Trombone',
  'Gaita',
  'Violoncelo',
  'Bateria Eletrônica',
  'Harpa',
  'Órgão',
  'Contrabaixo',
  'Pandeiro',
  'Tambor',
  'Trompa',
  'Maracas',
  'Bombo',
  'Xilofone',
  'Berimbau',
  'Ukulele',
  'Acordeão',
  'Pandeireta',
  'Trompa de Eustáquio',
  'Escaleta',
  'Fagote',
  'Bandolim',
  'Viola Caipira',
  'Cajón',
  'Tuba',
  'Sanfona',
  'Marimba',
];

export const MUSICAL_GENRE_OPTIONS = [
  'Pop',
  'Rock',
  'Hip-hop',
  'R&B',
  'Jazz',
  'Blues',
  'Country',
  'Reggae',
  'Dance',
  'Eletrônica',
  'Folk',
  'Clássica',
  'Indie',
  'Punk',
  'Metal',
  'Funk',
  'Soul',
  'Gospel',
  'Rap',
  'Salsa',
  'Bachata',
  'Reggaeton',
  'Samba',
  'Bossa Nova',
  'MPB',
  'Afrobeat',
  'Highlife',
  'Bollywood',
  'Hindustani',
  'Carnatic',
  'K-pop',
  'J-pop',
  'C-pop',
  'Flamenco',
  'Música Celta',
  'Taiko',
];

export const BAND_MEMBER_ROLES = ['Vocalista', 'Guitarrista', 'Staff'];
