<template>
  <div>
    <label
      class="text-indigo-400"
      v-if="!!label"
    >
      {{ label }}
    </label>
    <QuillEditor
      theme="bubble"
      v-model:content="textContent"
      content-type="html"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  label: { type: String, default: null },
  modelValue: { type: String, default: null },
});

const textContent = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});
</script>

<style scoped></style>
<style>
.ql-container {
  @apply bg-white rounded-sm h-20 text-base;
}
.ql-editor {
  padding: 5px;
}
</style>
