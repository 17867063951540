<template>
  <form
    class="w-4/5 p-10 rounded bg-slate-900 flex flex-col gap-5"
    @submit.prevent="handleSave"
  >
    <div class="md:flex gap-5 flex-wrap">
      <TextInput
        class="md:w-5/12"
        name="name"
        label="Nome"
        v-model="band.name"
      />

      <SelectInput
        class="md:w-4/12"
        name="musical_genres"
        label="Gênero musical"
        :options="genres"
        v-model="band.musical_genres"
        multiple
        taggable
      />
      <TextInput
        class="md:w-2/12"
        name="capacity"
        label="Capacidade"
        type="number"
        v-model="band.capacity"
      />

      <TextInput
        class="md:w-full"
        name="bio"
        label="Bio"
        type="text"
        v-model="band.bio"
      />

      <ImageInput
        class="md:w-full"
        name="cover_picture"
        label="Capa"
        :current-file-url="coverPic?.url"
        :on-file-change="onCoverPictureUpload"
      />
    </div>

    <div
      v-if="submitErrors?.length > 0"
      class="rounded w-full py-1 px-3 bg-rose-50 text-rose-500 mt-3 mb-3"
    >
      <ul>
        <li
          v-for="error in submitErrors"
          :key="error"
        >
          - {{ error }}
        </li>
      </ul>
    </div>

    <div class="flex items-center gap-5">
      <MButton
        variant="link"
        @on-click="$router.go(-1)"
      >
        Voltar
      </MButton>
      <MButton
        variant="primary"
        type="submit"
      >
        Salvar
      </MButton>
    </div>
  </form>
</template>

<script setup>
import { reactive, ref } from 'vue';
import MButton from '../../components/MButton.vue';
import TextInput from '../../components/TextInput.vue';
import SelectInput from '../../components/SelectInput.vue';
import { MUSICAL_GENRE_OPTIONS } from '../../config';
import ImageInput from '../../components/ImageInput.vue';
import { omitSingle } from '../../utils';

const props = defineProps(['onSave', 'submitErrors', 'initialValues']);

const genres = ref(MUSICAL_GENRE_OPTIONS);

const coverPic = ref(props.initialValues?.cover_picture);

const band = reactive(
  props.initialValues
    ? omitSingle('cover_picture', props.initialValues)
    : { name: '' },
);

const handleSave = () => {
  props.onSave(band);
};

const onCoverPictureUpload = (file) => {
  band.cover_picture = file;
};
</script>

<style scoped></style>
