<template>
  <Tooltip :disable="!user">
    <template #content>
      <div class="flex gap-2 items-center">
        <img
          :src="url"
          class="avatar w-[80px]"
        >
        <div class="flex flex-col">
          <div class="text-white">
            {{ user.name }}
          </div>
          <div class="text-indigo-300 text-sm hover:underline">
            <a :href="`/#/users/${user.id}`">Ver perfil</a>
          </div>
        </div>
      </div>
    </template>
    <img
      :src="url"
      class="avatar"
      :class="sizeClassMap[size]"
    >
  </Tooltip>
</template>

<script setup>
import Tooltip from './Tooltip.vue';

defineProps({
  url: { type: String, default: '' },
  size: { type: String, default: 'm' },
  user: { type: Object, default: null },
});

const sizeClassMap = {
  sm: 'small',
  m: 'medium',
  lg: 'large',
};
</script>

<style scoped>
.avatar {
  border-radius: 50%;
}
.small {
  width: 30px;
}
.medium {
  width: 40px;
}
.large {
  width: 140px;
}
</style>
