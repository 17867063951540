<template>
  <PageCard class="col-span-3">
    <div class="flex justify-between">
      <div class="flex gap-5 items-center">
        <img
          v-if="!!band.cover_picture?.url"
          :src="band.cover_picture?.url"
          class="w-40 hidden md:block"
          alt="Band cover image"
        >
        <span class="mb-2 flex flex-col">
          <span class="text-4xl text-white mb-2 flex flex-col">{{
            band.name
          }}</span>
          <div class="flex gap-2 flex-wrap">
            <Pill
              v-for="genre in band.musical_genres"
              :key="genre"
            >
              {{ genre }}
            </Pill>
          </div>
        </span>
      </div>
      <div class="flex flex-col justify-between items-end">
        <div v-if="currentUser.id === band.user_id">
          <MButton
            size="m"
            :icon="['fas', 'edit']"
            variant="link"
            @click="$router.push(`/bands/${band.id}/edit/`)"
          />
        </div>
        <!-- <MButton
          size="m"
          @click="$router.push(`/bands/${band.id}/edit/`)"
        >
          Solitar participação
        </MButton> -->
      </div>
    </div>
  </PageCard>
</template>

<script setup>
import { computed } from 'vue';
import useAuthStore from '../../stores/auth.store';
import MButton from '../MButton.vue';
import PageCard from '../PageCard.vue';
import Pill from '../Pill.vue';

const authStore = useAuthStore();
const currentUser = computed(() => authStore.currentUser);

defineProps({
  band: { type: Object, required: true },
});

</script>

<style lang="postcss" scoped></style>
