<template>
  <div class="flex justify-center w-full mt-20">
    <div
      class="flex justify-center flex-col bg-slate-800 rounded-lg shadow-lg p-8 max-w-md w-full"
    >
      <img src="/assets/images/logo.png">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Auth',
};
</script>

<style></style>
