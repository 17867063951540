<template>
  <AppPage :loading="loadingUser || !profileData">
    <div class="flex flex-col items-center">
      <p class="text-white text-center mb-4 mt-5 text-lg">
        Quanto mais nos contar sobre você, melhor será sua experiência!
      </p>
      <form
        class="w-full xs md:w-3/5 rounded flex flex-col gap-4"
        enctype="multipart/form-data"
        @submit.prevent="save"
      >
        <PageCard>
          <div class="form-title">
            Informações pessoais
          </div>
          <ImageInput
            name="profile_picture"
            label="Foto de perfil"
            :current-file-url="coverPic?.url"
            :on-file-change="onProfilePictureUpload"
          />

          <TextInput
            class="mb-4"
            name="name"
            label="Nome"
            v-model="profileData.name"
          />
          <RadioInput
            name="gender"
            label="Sexo"
            :options="genderOptions"
            v-model="profileData.gender"
          />

          <SelectInput
            name="state"
            label="Estado"
            :options="states"
            v-model="profileData.state"
          />

          <SelectInput
            name="city"
            label="Cidade"
            :options="cities"
            v-model="profileData.city"
          />

          <RichTextInput
            label="Bio"
            v-model="profileData.bio"
          />
        </PageCard>

        <PageCard>
          <div class="form-title">
            Preferências
          </div>
          <SelectInput
            name="selected_profile_type"
            label="Tipo de perfil"
            :options="profileData.available_profiles"
            v-model="profileData.selected_profile_type"
            pick-value
          />

          <SelectInput
            name="musical_genres"
            label="Influências"
            :options="genres"
            v-model="profileData.musical_genres"
            multiple
            taggable
          />

          <SelectInput
            name="skills"
            label="Talentos"
            :options="instruments"
            v-model="profileData.skills"
            multiple
            taggable
          />
        </PageCard>

        <PageCard>
          <div
            v-if="submitErrors?.length > 0"
            class="rounded w-full py-1 px-3 bg-rose-50 text-rose-500 mt-3 mb-3"
          >
            <ul>
              <li
                v-for="error in submitErrors"
                :key="error"
              >
                - {{ error }}
              </li>
            </ul>
          </div>

          <div class="flex items-center gap-5">
            <MButton
              variant="link"
              @on-click="$router.go(-1)"
            >
              Voltar
            </MButton>
            <MButton
              variant="primary"
              type="submit"
            >
              Salvar
            </MButton>
          </div>
        </PageCard>
      </form>
    </div>
  </AppPage>
</template>

<script>
import api from '../../api';
import AppPage from '../../components/AppPage.vue';
import MButton from '../../components/MButton.vue';
import RadioInput from '../../components/RadioInput.vue';
import TextInput from '../../components/TextInput.vue';
import SelectInput from '../../components/SelectInput.vue';
import ImageInput from '../../components/ImageInput.vue';
import useAuthStore from '../../stores/auth.store';
import brasil from '../../assets/brasil.json';
import {
  INSTRUMENT_OPTIONS,
  MUSICAL_GENRE_OPTIONS,
  STATE_OPTIONS,
} from '../../config';
import { omitSingle, pick, toSelectInputValue } from '../../utils';
import RichTextInput from '../../components/form/RichTextInput.vue';
import PageCard from '../../components/PageCard.vue';

export default {
  name: 'ProfilePage',
  components: {
    AppPage,
    MButton,
    RadioInput,
    TextInput,
    SelectInput,
    ImageInput,
    RichTextInput,
    PageCard,
  },
  async created() {
    this.loadingUser = true;
    api
      .get(`users/${this.$route.params.id}/`)
      .then((res) => {
        // TODO: MAKE IT A SERVICE, IT'S ALSO USED IN BAND FORM
        this.profileData = omitSingle('profile_picture', res.data);
        this.profileData.profile_type = toSelectInputValue(
          res.data.profile_type,
          res.data.available_profiles,
        );
        this.coverPic = res.data.profile_picture;
      })
      .finally(() => {
        this.loadingUser = false;
      });
  },
  data() {
    return {
      profileData: {
        gender: '',
      },
      loadingUser: false,
      genderOptions: [
        { value: 'm', label: 'Masculino' },
        { value: 'f', label: 'Feminino' },
        { value: 'o', label: 'Outro' },
      ],
      submitErrors: [],
      errors: {},
      cities: [],
      states: STATE_OPTIONS,
      instruments: INSTRUMENT_OPTIONS,
      genres: MUSICAL_GENRE_OPTIONS,
      coverPic: null,
    };
  },
  watch: {
    'profileData.state': function (newVal, oldVal) {
      if (newVal === oldVal) return;
      this.cities = brasil[this.profileData.state]?.cidades.map((c) => ({
        label: c,
        value: c,
      }));
    },
  },
  methods: {
    onProfilePictureUpload(file) {
      this.profileData.profile_picture = file;
    },
    save() {
      const formData = new FormData();

      const payload = pick(this.profileData, [
        'name',
        'gender',
        'state',
        'city',
        'profile_picture',
        'musical_genres',
        'skills',
        'bio',
      ]);

      payload.profile_type = this.profileData.selected_profile_type?.value;

      Object.keys(payload).forEach((key) => {
        if (Array.isArray(payload[key])) {
          payload[key].forEach((val) => {
            formData.append(`user[${key}][]`, val);
          });
        } else if (typeof payload[key] === 'object') {
          formData.append(`user[${key}]`, JSON.stringify(payload[key]));
        } else {
          formData.append(`user[${key}]`, payload[key]);
        }
      });

      api
        .patch(`users/${this.profileData.id}`, formData)
        .then((res) => {
          const authStore = useAuthStore();
          authStore.currentUser = res.data;
          this.$router.go(-1);
        })
        .catch((res) => {
          this.submitErrors = res.response.data.errors;
        })
        .finally(() => {
          this.errors = {};
        });
    },
  },
};
</script>

<style scoped>
.form-title {
  @apply text-lg text-yellow-500 font-bold mb-2;
}
</style>
