export const pick = (obj = {}, attrs = []) => {
  const newObj = {};
  attrs.forEach((attr) => {
    if (attr in obj) {
      newObj[attr] = obj[attr];
    }
  });
  return newObj;
};

export const omitSingle = (key, { [key]: _, ...obj }) => obj;

export const range = (start, end) => {
  const length = end - start;
  return Array.from({ length }, (_, i) => start + i);
};

export const toSelectInputValue = (value, options) => options.find((o) => o.value === value);
