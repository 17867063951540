import { DateTime } from 'luxon';

// eslint-disable-next-line import/prefer-default-export
export const sinceMonth = (date) => {
  const dt = DateTime.fromISO(date);
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  dt.setZone(tz);
  const format = { month: 'long', year: 'numeric' };
  return `${dt.setLocale(navigator.language).toLocaleString(format)}`;
};

// eslint-disable-next-line vue/max-len
export const monthPickDateTime = (datePickerRef) => DateTime.local(datePickerRef.year, datePickerRef.month + 1, 1);

export const extractForDatePicker = (date) => {
  if (!date) {
    return {
      month: DateTime.now().month,
      year: DateTime.now().year,
    };
  }
  const dt = DateTime.fromISO(date);
  return { month: dt.month - 1, year: dt.year };
};
