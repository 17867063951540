<template>
  <PageModal
    :show="show"
    @close-modal="onCloseModal"
    size="sm"
  >
    <h2 class="text-lg text-yellow-500">
      {{ title }}
    </h2>

    <div class="flex mt-4 justify-center">
      <MButton
        variant="primary"
        @on-click="onConfirmAction"
        :loading="loading"
      >
        Sim
      </MButton>
      <MButton
        variant="link"
        @on-click="onCloseModal"
      >
        Cancelar
      </MButton>
    </div>
  </PageModal>
</template>

<script>
import MButton from './MButton.vue';
import PageModal from './PageModal.vue';

export default {
  components: { PageModal, MButton },
  props: {
    onConfirmAction: { type: Function, required: true },
    onCloseModal: { type: Function, required: true },
    show: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    title: { type: String, default: 'Tem certeza que deseja continuar?' },
  },
};
</script>

<style lang="postcss" scoped></style>
