import axios from 'axios';
import { defineStore } from 'pinia';
import api from '../api';

// const baseUrl = `${import.meta.env.VITE_API_URL}/`;

const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    jwt_token: localStorage.getItem('jwt_token'),
    currentUser: null,
  }),
  actions: {
    async login(email, password) {
      const res = await axios.post('api/auth/login/', {
        email,
        password,
      });
      // update pinia state
      this.jwt_token = res.data.token;

      this.currentUser = res.data.user;

      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem('jwt_token', this.jwt_token);
    },
    async logout() {
      try {
        await api.post('auth/logout/');
        this.jwt_token = null;
        this.currentUser = null;
        localStorage.removeItem('jwt_token');
      } catch (err) {
        console.error('Something went wrong with logout', err);
      }
    },
    async fetchCurrentUser() {
      try {
        const res = await api.get('auth/current/');
        this.currentUser = res.data;
      } catch (err) {
        this.jwt_token = null;
        this.currentUser = null;
        localStorage.removeItem('jwt_token');
      }
    },
  },
});

export default useAuthStore;
