<template>
  <div>
    <label
      class="text-indigo-400"
      v-if="!!label"
      :for="name"
    >
      {{ label }}
    </label>
    <VueDatePicker
      :model-value="date"
      v-model="input"
      @update:model-value="handleDate"
      :month-picker="monthPicker"
      :teleport="true"
      locale="pt-BR"
      :name="name"
    />
    <div
      class="rounded w-full py-1 px-3 bg-rose-50 text-rose-500 mt-1"
      v-if="!!error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { monthPickDateTime, extractForDatePicker } from '../dates';

const props = defineProps([
  'modelValue',
  'label',
  'name',
  'error',
  'monthPicker',
]);
const emit = defineEmits(['update:modelValue']);
const input = ref(extractForDatePicker(props.modelValue));

const date = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});

const handleDate = (modelData) => {
  input.value = modelData;
  date.value = monthPickDateTime(modelData).toString();
};
</script>

<style>
.dp__input {
  color: theme('colors.indigo.600');
}
.dp__theme_light {
  --dp-primary-color: theme('colors.indigo.700');
}

.dp__action_select {
  background-color: theme('colors.indigo.700') !important;
}
.dp__action_select:hover {
  background-color: theme('colors.indigo.500') !important;
}
</style>
