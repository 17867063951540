<template>
  <router-view v-slot="{ Component }">
    <Suspense timeout="0">
      <component
        :is="Component"
        :key="route.path"
      />
      <template #fallback>
        <div
          class="fixed flex items-center justify-center w-full h-full top-0 bottom-0"
        >
          <font-awesome-icon
            size="4x"
            :icon="['fas', 'spinner']"
            class="text-white"
            spin
          />
        </div>
      </template>
    </Suspense>
  </router-view>
</template>

<script setup>
import { useRoute } from 'vue-router';

const route = useRoute();
</script>

<style></style>
