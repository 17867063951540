<template>
  <AppPage :loading="data.loadingBand || !data.band">
    <div class="mt-10">
      <BandHeader
        :band="data.band"
        class="mb-5"
      />
      <div class="md:grid md:grid-cols-3 md:grid-flow-col flex flex-col gap-5">
        <div class="row-span-10 col-span-2">
          <PageCard>
            <div class="text-white">
              <div class="text-2xl text-yellow-500">
                Bio
              </div>
              <p>
                {{ data.band?.bio }}
              </p>
            </div>
          </PageCard>
        </div>
        <div class="md:col-span-1">
          <PageCard>
            <div class="flex flex-col text-white">
              <div class="mb-5">
                <div class="text-2xl text-yellow-500">
                  Membros
                </div>
                <div>
                  <MButton
                    size="sm"
                    @click="openNewMemberModal"
                  >
                    + Adicionar membros
                  </MButton>
                </div>
                <div class="flex flex-wrap gap-2 mt-2">
                  <Pill
                    :key="member.id"
                    v-for="member in data.band?.members"
                    @on-remove="() => handleRemoveMember(member)"
                    editable
                  >
                    <template #image>
                      <UserAvatar
                        :url="member.profile_picture.url"
                        size="sm"
                        :user="member"
                        v-if="!!member.profile_picture.url"
                      />
                    </template>

                    <span class="ml-2">{{ member.name }}</span>
                  </Pill>
                </div>
              </div>
            </div>
          </PageCard>
        </div>
      </div>
    </div>

    <ConfirmActionModal
      :show="!!memberToBeDeleted"
      :title="confirmRemoveMemberTitle"
      :on-close-modal="closeRemoveMemberModal"
      :on-confirm-action="handleConfirmRemoveMember"
      :loading="data.removingMember"
    />

    <NewMemberModal
      :show="showMemberModal"
      :on-member-added="handleMemberAdded"
      :on-close-modal="closeNewMemberModal"
    />
  </AppPage>
</template>
<script setup>
import { useRoute } from 'vue-router';
import {
  computed, onMounted, reactive, ref,
} from 'vue';
import AppPage from '../../components/AppPage.vue';
import api from '../../api';
import MButton from '../../components/MButton.vue';
import UserAvatar from '../../components/UserAvatar.vue';
import PageCard from '../../components/PageCard.vue';
import NewMemberModal from '../../components/NewMemberModal.vue';
import BandHeader from '../../components/band/BandHeader.vue';
import ConfirmActionModal from '../../components/ConfirmActionModal.vue';
import Pill from '../../components/Pill.vue';

const route = useRoute();
const bandId = route.params.id;

const data = reactive({
  band: null,
  loadingBand: false,
  removingMember: false,
});

const showMemberModal = ref(false);
const memberToBeDeleted = ref(null);

const confirmRemoveMemberTitle = computed(
  () => `Deseja remover ${memberToBeDeleted.value?.name} da banda?`,
);

const fetchBand = () => {
  data.loadingBand = true;
  api
    .get(`bands/${bandId}`)
    .then((res) => {
      data.band = res.data;
    })
    .finally(() => {
      data.loadingBand = false;
    });
};

onMounted(() => {
  fetchBand();
});

const openNewMemberModal = () => {
  showMemberModal.value = true;
};

const closeNewMemberModal = () => {
  showMemberModal.value = false;
};

const handleMemberAdded = () => {
  closeNewMemberModal();
  fetchBand();
};

const handleRemoveMember = (member) => {
  memberToBeDeleted.value = member;
};

const closeRemoveMemberModal = () => {
  memberToBeDeleted.value = null;
};

const handleConfirmRemoveMember = () => {
  data.removingMember = true;
  api
    .delete(`/bands/${bandId}/members/${memberToBeDeleted.value?.id}`)
    .then(() => {
      fetchBand();
      memberToBeDeleted.value = null;
    })
    .finally(() => {
      data.removingMember = false;
    });
};
</script>

<style scoped></style>
