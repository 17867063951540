<template>
  <button
    @click="handleClick"
    :class="btnClassObject"
    :type="type"
    :disabled="loading || disabled"
  >
    <font-awesome-icon
      :icon="['fas', 'spinner']"
      spin
      v-if="loading"
    />
    <font-awesome-icon
      v-if="!loading && !!icon"
      :icon="icon"
      :class="iconClassObject"
    />
    <slot
      v-if="!loading"
      class="ml-[8px]"
    />
  </button>
</template>

<script>
export default {
  props: {
    type: { type: String, default: 'button' },
    variant: { type: String, default: 'primary' },
    // eslint-disable-next-line vue/require-default-prop
    icon: [Array, String],
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    size: { type: String, default: 'm' },
    fullWidth: Boolean,
  },
  computed: {
    iconClassObject() {
      return {
        'text-sm': this.size === 'sm',
        'text-lg': this.size === 'm',
      };
    },
    btnClassObject() {
      const variantCss = {
        'w-full': this.fullWidth,
        'border-indigo-400 hover:bg-indigo-400 hover:text-white text-indigo-400':
          this.variant === 'primary',
        'inline-block align-baseline text-sm text-indigo-400 hover:underline':
          this.variant === 'link',
        'border-solid border-2 font-bold rounded focus:outline-none focus:shadow-outline':
          this.variant !== 'link',
      };

      const sizeCss = {
        'py-0 px-1 text-xs': this.size === 'sm',
        'py-2 px-4': this.size === 'm',
      };

      return { ...variantCss, ...sizeCss };
    },
  },
  methods: {
    handleClick() {
      this.$emit('onClick');
    },
  },
};
</script>

<style scoped></style>
