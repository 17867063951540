<template>
  <div>
    <label
      class="text-indigo-400"
      v-if="!!label"
    >
      {{ label }}
    </label>
    <v-select
      class="rounded w-full bg-white text-indigo-600 focus:outline-none focus:shadow-outline"
      :filterable="false"
      :options="users"
      :multiple="multiple"
      @search="onSearch"
      v-model="selectedUsers"
    >
      <template #no-options>
        Buscar pessoas...
      </template>
      <template #option="option">
        <div class="d-center flex flex-row items-center">
          <UserAvatar
            size="sm"
            :url="option.profile_picture?.url"
            class="mr-2"
          />
          <span>{{ option.name }}</span>
        </div>
      </template>
      <template #selected-option="option">
        <div class="d-center flex flex-row items-center mr-2">
          <UserAvatar
            size="sm"
            :url="option.profile_picture?.url"
            class="mr-2"
            v-if="!!option.profile_picture?.url"
          />
          {{ option.name }}
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import debounce from 'debounce';
import api from '../api';
import UserAvatar from './UserAvatar.vue';

export default {
  components: { UserAvatar },
  emits: ['on-select'],
  props: {
    multiple: { type: Boolean, default: false },
    label: { type: String, default: null },
  },
  data() {
    return {
      searchQuery: '',
      users: [],
      loadingUsers: false,
      selectedUsers: [],
    };
  },
  watch: {
    selectedUsers(newVal) {
      this.$emit('on-select', newVal);
    },
  },
  methods: {
    onSearch(search, loading) {
      if (search.length || (!search.length && !!this.users?.length)) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce((loading, search, vm) => {
      api
        .get(`users/?q=${search}`)
        .then((res) => {
          vm.users = res.data;
        })
        .finally(() => {
          loading(false);
        });
    }, 350),
  },
};
</script>
