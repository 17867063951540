<template>
  <div>
    <div class="text-indigo-400">
      {{ label }}
    </div>
    <div
      class="rounded w-full py-1 px-3 bg-rose-50 text-rose-500 mt-1"
      v-if="!!error"
    >
      {{ error }}
    </div>
    <div class="flex flex-col">
      <div
        v-for="option in options"
        :key="option.value"
      >
        <input
          :id="option.value"
          class="mr-2"
          type="radio"
          :name="name"
          :value="option.value"
          :checked="selectedOption === option.value"
          @input="updateSelectedOption(option.value)"
        >
        <label
          class="text-white"
          :for="option.value"
        >{{ option.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioInput',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
  },
  computed: {
    selectedOption: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  methods: {
    updateSelectedOption(optionValue) {
      this.selectedOption = optionValue;
    },
  },
};
</script>

<style scoped></style>
