<template>
  <form
    class="w-full md:w-4/5 p-10 rounded bg-slate-900 flex flex-col gap-5"
    @submit.prevent="handleSave"
  >
    <div class="md:flex gap-5 flex-wrap">
      <TextInput
        name="name"
        label="Nome"
        v-model="instrument.name"
      />
      <TextInput
        name="model"
        label="Modelo"
        v-model="instrument.model"
      />

      <DatePicker
        v-model="instrument.plays_since"
        label="Toca desde"
        month-picker
      />
    </div>

    <div
      v-if="submitErrors?.length > 0"
      class="rounded w-full py-1 px-3 bg-rose-50 text-rose-500 mt-3 mb-3"
    >
      <ul>
        <li
          v-for="error in submitErrors"
          :key="error"
        >
          - {{ error }}
        </li>
      </ul>
    </div>

    <div class="flex items-center gap-5">
      <MButton
        variant="link"
        @on-click="$router.go(-1)"
      >
        Voltar
      </MButton>
      <MButton
        variant="primary"
        type="submit"
      >
        Salvar
      </MButton>
    </div>
  </form>
</template>

<script setup>
import { reactive } from 'vue';
import MButton from '../../components/MButton.vue';
import TextInput from '../../components/TextInput.vue';
import DatePicker from '../../components/DatePicker.vue';

const props = defineProps(['onSave', 'submitErrors', 'initialValues']);

const instrument = reactive(
  props.initialValues ? props.initialValues : { name: '' },
);

const handleSave = () => {
  props.onSave(instrument);
};
</script>
