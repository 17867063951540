<template>
  <div
    class="inline-block relative"
    @mouseover="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <slot />

    <div
      v-if="showTooltip && !disable"
      class="tooltip w-max"
      :class="[positionClasses[position]]"
    >
      <slot
        name="content"
        v-if="!content"
      />
      <div v-else>
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'top',
      validator: (value) => ['top', 'bottom', 'left', 'right'].includes(value),
    },
    content: {
      type: String,
      default: null,
    },
    disable: { type: Boolean, default: false },
  },
  data() {
    return {
      showTooltip: false,
      positionClasses: {
        top: 'tooltip-top',
        bottom: 'tooltip-bottom',
        left: 'tooltip-left',
        right: 'tooltip-right',
      },
    };
  },
};
</script>

<style scoped>
.tooltip {
  @apply absolute z-10 p-2 text-white bg-slate-800 rounded-md whitespace-nowrap after:content-none border min-w-[50px];
}

.tooltip::before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;

  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid white;
}

.tooltip-top {
  @apply bottom-full;
}

.tooltip-top::before {
  @apply -rotate-90 top-full;
}

.tooltip-bottom {
  @apply top-full -right-2/3;
}
.tooltip-bottom::before {
  @apply rotate-90 bottom-full left-1/2;
}

.tooltip-left {
  @apply right-full top-0 mr-2;
}
.tooltip-left::before {
  @apply left-full top-1/4 rotate-180;
}

.tooltip-right {
  @apply left-full top-0 ml-4;
}
.tooltip-right::before {
  @apply top-1/4 right-full;
}
</style>
