<template>
  <AppPage :loading="data.loadingInstrument">
    <div class="flex flex-col items-center">
      <h1 class="text-4xl text-white mb-2 mt-8">
        Editar instrumento
      </h1>

      <InstrumentForm
        v-if="!!data.instrument"
        :initial-values="data.instrument"
        :on-save="onSave"
        :submit-errors="data.submitErrors || []"
      />
    </div>
  </AppPage>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, reactive } from 'vue';
import api from '../../api';
import AppPage from '../../components/AppPage.vue';

import InstrumentForm from './InstrumentForm.vue';

const router = useRouter();
const data = reactive({ submitErrors: [], instrument: null, loadingInstrument: false });

const route = useRoute();
const instrumentId = route.params.instrument_id;

onMounted(() => {
  data.loadingInstrument = true;
  api
    .get(`musical_instruments/${instrumentId}`)
    .then((res) => {
      data.instrument = res.data;
    })
    .finally(() => {
      data.loadingInstrument = false;
    });
});

const onSave = (payload) => {
  const formData = new FormData();

  Object.keys(payload).forEach((key) => {
    if (Array.isArray(payload[key])) {
      payload[key].forEach((val) => {
        formData.append(`musical_instrument[${key}][]`, val);
      });
    } else {
      formData.append(`musical_instrument[${key}]`, payload[key]);
    }
  });

  api
    .patch(`musical_instruments/${instrumentId}`, formData)
    .then(() => {
      router.go(-1);
    })
    .catch((res) => {
      data.submitErrors = res.response.data.errors;
    });
};
</script>
