<template>
  <PageModal
    :show="show"
    @close-modal="onCloseModal"
    size="md"
  >
    <!-- Content for your modal -->
    <div class="text-yellow-500 text-xl mb-3">
      Adicionar novo membro
    </div>
    <UserPicker
      label="Membro"
      @on-select="onSelectMember"
    />
    <SelectInput
      name="role"
      label="Função"
      :options="roles"
      v-model="role"
    />

    <div class="mt-5 flex justify-center">
      <MButton
        @on-click="sendNewMembers"
        :loading="state.isAddingMember"
      >
        Adicionar
      </MButton>
    </div>
  </PageModal>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import api from '../api';
import PageModal from './PageModal.vue';
import UserPicker from './UserPicker.vue';
import SelectInput from './SelectInput.vue';
import { BAND_MEMBER_ROLES } from '../config';
import MButton from './MButton.vue';

const props = defineProps({
  show: { type: Boolean, default: false },
  onCloseModal: { type: Function, default: null },
  onMemberAdded: { type: Function, default: null },
});

const route = useRoute();
const bandId = route.params.id;

const role = ref(null);
const roles = BAND_MEMBER_ROLES;

const state = reactive({
  selectedUser: null,
  isAddingMember: false,
});

const sendNewMembers = () => {
  state.isAddingMember = true;
  api
    .post(`/bands/${bandId}/members`, {
      members_ids: [state.selectedUser.id],
      role: role.value,
    })
    .then(() => {
      props.onCloseModal();
      props.onMemberAdded();
    })
    .finally(() => {
      state.isAddingMember = false;
    });
};

const onSelectMember = (user) => {
  state.selectedUser = user;
};
</script>

<style scoped></style>
