<template>
  <div>
    <label
      class="text-indigo-400"
      :for="name"
      v-if="!!label"
    >
      {{ label }}
    </label>
    <input
      class="rounded w-full py-2 px-3 text-indigo-600 leading-tight focus:outline-none focus:shadow-outline"
      :id="name"
      :name="name"
      :type="type"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >
    <div
      class="rounded w-full py-1 px-3 bg-rose-50 text-rose-500 mt-1"
      v-if="!!error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    modelValue: [String, Number],
    label: String,
    name: String,
    error: String,
    type: { type: String, default: 'text' },
  },
};
</script>

<style scoped></style>
