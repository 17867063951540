<template>
  <AppPage>
    <div class="flex flex-col items-center">
      <h1 class="text-4xl text-white mb-2 mt-8">
        Nova banda
      </h1>

      <p class="text-white text-center mb-4">
        Conta mais sobre sua banda!
      </p>

      <BandForm
        :on-save="onSave"
        :submit-errors="data.submitErrors || []"
      />
    </div>
  </AppPage>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { reactive } from 'vue';
import api from '../../api';
import AppPage from '../../components/AppPage.vue';
import useAuthStore from '../../stores/auth.store';

import BandForm from './BandForm.vue';

const router = useRouter();
const data = reactive({ submitErrors: [] });
const authStore = useAuthStore();

const onSave = (payload) => {
  api
    .post('bands', { ...payload, user_id: authStore.currentUser.id })
    .then(() => {
      router.push('/');
    })
    .catch((res) => {
      data.submitErrors = res.response.data.errors;
    });
};
</script>
