<template>
  <form
    class="mt-2"
    @submit.prevent="login"
  >
    <div class="mb-4">
      <TextInput
        v-model="loginData.email"
        name="email"
        label="E-mail"
        required
      />
    </div>
    <div class="mb-6">
      <TextInput
        v-model="loginData.password"
        name="password"
        type="password"
        label="Senha"
        required
      />
    </div>
    <div class="flex flex-col items-center">
      <MButton
        variant="primary"
        full-width
        type="submit"
        :loading="loading"
      >
        Entrar
      </MButton>
      <MButton
        class="mt-3"
        variant="link"
      >
        Esqueceu sua senha?
      </MButton>

      <div class="my-3 w-full border-t-[1px] border-indigo-300" />

      <MButton
        class="mt-3"
        variant="primary"
        full-width
        @on-click="$router.push('/auth/signup/')"
      >
        Criar conta
      </MButton>
    </div>
  </form>
</template>

<script>
import { useToast } from 'vue-toastification';
import useAuthStore from '../stores/auth.store';
import MButton from '../components/MButton.vue';
import TextInput from '../components/TextInput.vue';

export default {
  name: 'LoginPage',
  components: { MButton, TextInput },
  data() {
    return {
      loading: false,
      loginData: {},
      errors: {},
      submitErrors: [],
    };
  },
  methods: {
    async login() {
      const authStore = useAuthStore();
      const $toast = useToast();
      this.loading = true;
      try {
        await authStore.login(this.loginData.email, this.loginData.password);
        this.$router.push('/');
      } catch (error) {
        $toast.error('E-mail ou senha inválidos.');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
