<template>
  <AppPage>
    <div class="flex flex-col items-center">
      <h1 class="text-4xl text-white mb-2 mt-8">
        Novo instrumento
      </h1>

      <InstrumentForm
        :on-save="onSave"
        :submit-errors="data.submitErrors || []"
      />
    </div>
  </AppPage>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { reactive } from 'vue';
import api from '../../api';
import AppPage from '../../components/AppPage.vue';
import useAuthStore from '../../stores/auth.store';
import InstrumentForm from './InstrumentForm.vue';

const router = useRouter();
const data = reactive({ submitErrors: [] });
const authStore = useAuthStore();

const onSave = (payload) => {
  api
    .post('musical_instruments', {
      ...payload,
      user_id: authStore.currentUser.id,
    })
    .then(() => {
      router.go(-1);
    })
    .catch((res) => {
      data.submitErrors = res.response.data.errors;
    });
};
</script>
