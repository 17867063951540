<template>
  <div class="flex gap-2 text-indigo-100 items-center justify-center">
    <div
      class="page-item"
      v-if="currentPage > 1"
      @click="() => onPageChange(currentPage - 1)"
    >
      <font-awesome-icon
        icon="fa-solid fa-caret-left"
        size="sm"
      />
    </div>
    <span
      v-for="(page, i) in pages"
      :key="page"
      class="flex gap-2"
    >
      <div
        class="page-item"
        :class="{ active: currentPage === page }"
        @click="() => onPageChange(page)"
      >
        {{ page }}
      </div>
      <div v-if="pages[i + 1] && page + 1 !== pages[i + 1]">...</div>
    </span>

    <div
      class="page-item"
      v-if="currentPage < pagesNumber"
      @click="() => onPageChange(currentPage + 1)"
    >
      <font-awesome-icon
        icon="fa-solid fa-caret-right"
        size="sm"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { range } from '../utils';

const emit = defineEmits(['pageChange']);

const props = defineProps({
  pagesNumber: { type: Number, default: 0 },
  currentPage: { type: Number, default: 0 },
  currentPageOffset: { type: Number, default: 0 },
});

const pagesNumber = ref(props.pagesNumber);
const currentPage = ref(props.currentPage);
const currentPageOffset = ref(props.currentPageOffset);

const getPagesFractionRange = (start, end) => {
  const rangeEnd = pagesNumber.value + 1;
  return range(start >= 1 ? start : 1, end <= rangeEnd ? end : rangeEnd);
};

const getPageNumbersToDisplay = () => {
  // To figure what are the pages we want to show we are going to
  // add to the `pages` array all the pages we want and then do a clenup by
  // removing dulicates and them sorting ther array. Eg.:
  // Imagine we are dealing with 10 pages and currentPage is 4
  // If we allways want to show the first 3 pages we will do:
  // > pages.push(1, 2, 3)
  // If we want to add 2 surrounding pages of the current pages:
  // > pages.push(2, 3, 4, 5, 6)
  // Then the last 3 pages
  // > pages.push(8, 9, 10)
  // `pages` will then be:
  // [1, 2, 3, 2, 3, 4, 5, 6, 8, 9, 10]
  // For the final result we remove the duplicates and sort
  const rangeEnd = pagesNumber.value + 1;
  let pages = [];
  const edgeOffset = 1;

  // starting pages
  const endOfFirstPages = pagesNumber.value <= edgeOffset ? pagesNumber.value + 1 : edgeOffset + 1;
  pages = range(1, endOfFirstPages);

  // middle of the pages
  // const middle = Math.ceil(pagesNumber.value / 2);
  // pages = pages.concat(
  //   getPagesFractionRange(middle - 1, middle + 1, pagesNumber),
  // );

  // surrounding current page
  const startOfSurroundingCurrentPage = currentPage.value - currentPageOffset.value;
  const endOfSurroundingCurrentPage = currentPage.value + currentPageOffset.value + 1;
  pages = pages.concat(
    getPagesFractionRange(
      startOfSurroundingCurrentPage,
      endOfSurroundingCurrentPage,
      pagesNumber,
    ),
  );

  // end
  const startOfLastPages = rangeEnd - edgeOffset;
  pages = pages.concat(
    getPagesFractionRange(startOfLastPages, rangeEnd, pagesNumber),
  );

  return [...new Set(pages)].sort((a, b) => a - b);
};

const onPageChange = (page) => {
  emit('pageChange', page);
};

const pages = getPageNumbersToDisplay();
</script>

<style scoped>
.active {
  @apply bg-indigo-800 text-indigo-100;
}
</style>
<style>
.page-item {
  @apply flex justify-center items-center border rounded-md text-indigo-100 border-indigo-100 h-8 min-w-[30px] cursor-pointer pl-1 pr-1;
  @apply hover:border-indigo-300 hover:text-indigo-300;
}
</style>
