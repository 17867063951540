<template>
  <AppPage :loading="data.loadingBand">
    <div class="flex flex-col items-center">
      <h1 class="text-4xl text-white mb-2 mt-8">
        Editar banda
      </h1>

      <p class="text-white text-center mb-4">
        Conta mais sobre sua banda!
      </p>

      <BandForm
        v-if="!!data.band"
        :initial-values="data.band"
        :on-save="onSave"
        :submit-errors="data.submitErrors || []"
      />
    </div>
  </AppPage>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, reactive } from 'vue';
import api from '../../api';
import AppPage from '../../components/AppPage.vue';

import BandForm from './BandForm.vue';

const router = useRouter();
const data = reactive({ submitErrors: [], band: null, loadingBand: false });

const route = useRoute();
const bandId = route.params.id;

onMounted(() => {
  data.loadingBand = true;
  api
    .get(`bands/${bandId}`)
    .then((res) => {
      data.band = res.data;
    })
    .finally(() => {
      data.loadingBand = false;
    });
});

const onSave = (payload) => {
  const formData = new FormData();

  Object.keys(payload).forEach((key) => {
    if (Array.isArray(payload[key])) {
      payload[key].forEach((val) => {
        formData.append(`band[${key}][]`, val);
      });
    } else {
      formData.append(`band[${key}]`, payload[key]);
    }
  });

  api
    .patch(`bands/${bandId}`, formData)
    .then(() => {
      router.push(`/bands/${bandId}/`);
    })
    .catch((res) => {
      data.submitErrors = res.response.data.errors;
    });
};
</script>
